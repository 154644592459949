<template>
  <div class="sidebar-right-container tw-w-full tw-items-center">
    <zem-card class="tw-gap-x-3 tw-flex tw-flex-col">
      <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
        <zem-card-title class="tw-mb-0">
          {{ isCreate ? 'Создание мероприятия' : `Мероприятие: ${element.type_id.title}` }}
        </zem-card-title>
        <div class="tw-flex tw-items-center tw-gap-x-3 cursor-pointer">
          <zem-link class="md:tw-hidden tw-w-5" @click="closeRightSidebar">
            <img alt="close" src="@/assets/icons/close.svg" />
          </zem-link>
          <zem-link class="max-md:tw-hidden" @click="closeRightSidebar">Закрыть</zem-link>
          <zem-button
            @click="
              () => {
                isCreate ? onCreateEvent() : onChangeEvent()
              }
            "
          >
            Сохранить
          </zem-button>
        </div>
      </div>
      <template v-if="!isCreate">
        <div class="tw-flex tw-items-center tw-text-xs tw-text-gray-700 tw-mt-1">
          <span class="tw-w-1.5 tw-h-1.5 tw-bg-[#0EC6C6] tw-rounded-full tw-mr-1"></span>
          ID #{{ element.real_id }}
        </div>
        <div v-if="!closeAcc([3])" class="tw-flex tw-items-center tw-text-xs tw-text-gray-700">
          <span class="tw-w-1.5 tw-h-1.5 tw-bg-[#0B9999] tw-rounded-full tw-mr-1"></span>
          ID {{ element.id }}
        </div>
      </template>
    </zem-card>

    <zem-collapse is-opened-default title="Общее">
      <div class="tw-grid tw-grid-cols-3 tw-gap-x-1.5 tw-gap-y-2">
        <div class="tw-text-[#9ba6b2] tw-text-xs">Заказ</div>
        <div class="tw-col-span-2 tw-flex tw-items-center tw-gap-x-2">
          <zem-dropdown-list
            v-model="element.order_id"
            :loading="isLoadingOrders"
            :options="orders"
            class="tw-mt-0"
            search
            @on-search="getOrders"
          />
          <zem-dropdown-menu>
            <template v-slot:options>
              <RouterLink
                :to="`/orders/${element.order_id.value}`"
                class="tw-block tw-text-nowrap tw-px-3 tw-text-xs tw-py-1 hover:tw-bg-[#0DB2B2]/20 tw-text-[#3E3E3E]"
                target="_blank"
              >
                Перейти в карточку
              </RouterLink>
            </template>
          </zem-dropdown-menu>
        </div>

        <div class="tw-text-[#9ba6b2] tw-text-xs">Тип</div>
        <div class="tw-col-span-2">
          <zem-dropdown-list
            v-model="element.type_id"
            :loading="isLoadingTypes"
            :options="types"
            class="tw-mt-0"
            @open="getTypes"
          />
        </div>

        <div class="tw-text-[#9ba6b2] tw-text-xs">Описание</div>
        <div class="tw-col-span-2 tw-leading-[0.6]">
          <zem-textarea v-model="element.description" class="tw-m-0" placeholder="Описание" />
        </div>

        <div class="tw-text-[#9ba6b2] tw-text-xs">Статус</div>
        <div class="tw-col-span-2">
          <zem-dropdown-list
            v-model="element.status_id"
            :loading="isLoadingStatuses"
            :options="statuses"
            class="tw-mt-0"
            @open="getStatuses"
          />
        </div>

        <div class="tw-text-[#9ba6b2] tw-text-xs">Особенности</div>
        <div class="tw-col-span-2">
          <v-select
            v-model="element.features.data"
            :components="{OpenIndicator}"
            :options="peculiaritiesOptions"
            class="my-0"
            label="title"
            multiple
            placeholder="Выбрать"
          >
            <template #open-indicator="{attributes}">
              <img :src="require('@/assets/icons/dropdown-arrow.svg')" alt="" />
            </template>
            <template #no-options="{search, searching}">
              <template v-if="searching">
                Результаты не найдены для <em>{{ search }}</em>
              </template>
              <template v-else>Начните вводить текст для поиска</template>
            </template>
          </v-select>
        </div>

        <div class="tw-text-[#9ba6b2] tw-text-xs">Подтвердить</div>
        <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
          <zem-checkbox v-model="isConfirmed" />
        </div>

        <template v-if="!isCreate">
          <div class="tw-text-[#9ba6b2] tw-text-xs">Подтверждение</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
            {{ element.confirmation_status ? element.confirmation_status.title : '' }}
          </div>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Дата создания</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
            {{ element.created_at ? dayjs.unix(element.created_at).format('DD.MM.YYYY в HH:mm') : '' }}
          </div>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Кто создал</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
            {{ element.created_by ? element.createdBy.data.name : '' }}
          </div>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Дата изменения</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
            {{ element.updated_at ? dayjs.unix(element.updated_at).format('DD.MM.YYYY в HH:mm') : '' }}
          </div>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Кто изменил</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
            {{ element.updated_by ? element.updatedBy.data.name : '' }}
          </div>
        </template>
      </div>
    </zem-collapse>

    <zem-collapse is-opened-default title="Участники и сроки">
      <div class="tw-divide-y tw-divide-[#F0F0F0] tw-flex tw-flex-col tw-gap-y-2.5">
        <div
          v-for="(people, index) in peoples"
          :key="index"
          :class="{'tw-pt-2.5': index !== 0}"
          class="tw-grid tw-grid-cols-[1fr_100px_1fr] tw-gap-x-1.5 tw-gap-y-2"
        >
          <div class="tw-col-span-3 tw-leading-[0.6] tw-relative">
            <div class="tw-text-[#9ba6b2] tw-text-xs tw-flex tw-justify-between tw-mb-1">
              <span>Участник</span>
              <zem-dropdown-menu>
                <template v-slot:options>
                  <RouterLink
                    :to="`/counterparties/peoples/${people.user.value}`"
                    class="tw-block tw-text-nowrap tw-px-3 tw-py-1 hover:tw-bg-[#0DB2B2]/20 tw-text-[#3E3E3E]"
                    target="_blank"
                  >
                    Перейти в карточку
                  </RouterLink>
                  <p
                    class="tw-text-nowrap tw-px-3 tw-py-1 hover:tw-bg-[#0DB2B2]/20 tw-text-[#3E3E3E]"
                    @click="deletePeople(index)"
                  >
                    Удалить исполнителя
                  </p>
                </template>
              </zem-dropdown-menu>
            </div>
            <zem-dropdown-list
              v-model="people.user"
              :loading="people.isLoading"
              :options="listPeoples"
              class="tw-mt-0 tw-w-1/2"
              search
              @on-search="getPeoples(index, $event)"
            />
          </div>

          <div class="tw-leading-[0.6]">
            <p class="tw-text-[#9ba6b2] tw-text-xs tw-mb-1">Начало</p>
            <date-picker
              v-model="people.dateStart"
              class="tw-w-full"
              format="DD.MM.YYYY в HH:mm"
              locale="ru"
              type="datetime"
              @input="updateTimes('start', index, $event)"
            ></date-picker>
          </div>

          <div class="tw-leading-[0.6]">
            <p class="tw-text-[#9ba6b2] tw-text-xs tw-mb-1">Продолж.</p>
            <zem-counter
              v-model="people.duration"
              :suffix="formatTime(people.duration)"
              class="mt-0"
              @update="updateTimes('duration', index, $event)"
            />
          </div>

          <div class="tw-leading-[0.6]">
            <p class="tw-text-[#9ba6b2] tw-text-xs tw-mb-1">Завершение</p>
            <date-picker
              v-model="people.dateEnd"
              class="tw-w-full"
              format="DD.MM.YYYY в HH:mm"
              locale="ru"
              type="datetime"
              @change="updateTimes('end', index, $event)"
            ></date-picker>
          </div>
        </div>
      </div>
      <button class="tw-flex tw-items-center tw-gap-1 tw-mt-4">
        <img :src="require('@/assets/icons/plus_primary.svg')" alt="" class="tw-w-3.5" />
        <span
          class="tw-text-[#0DB2B2] tw-cursor-pointer tw-text-xs tw-font-semibold"
          @click="
            peoples.push({
              user: '',
              dateStart: null,
              duration: 0,
              dateEnd: null,
              isLoading: false,
              durationSuffix: '0 мин',
            })
          "
        >
          участник
        </span>
      </button>
    </zem-collapse>

    <zem-collapse v-if="element.order && element.order.data.object" is-opened-default title="Объект">
      <div class="tw-grid tw-grid-cols-3 tw-gap-x-1.5 tw-gap-y-2">
        <div class="tw-text-[#9ba6b2] tw-text-xs">Артикул объекта</div>
        <RouterLink
          :to="`/objects/${element.order.data.object.data.id}`"
          class="tw-col-span-2 tw-text-xs tw-text-[#0DB2B2] hover:tw-underline tw-cursor-pointer"
          target="_blank"
        >
          {{ element.order.data.object.data.sku }}
        </RouterLink>

        <div class="tw-text-[#9ba6b2] tw-text-xs">Регион</div>
        <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
          {{ element.order.data.object.data.region ? element.order.data.object.data.region.data.name : '' }}
        </div>

        <div class="tw-text-[#9ba6b2] tw-text-xs">Шоссе</div>
        <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
          {{ element.order.data.object.data.highway ? element.order.data.object.data.highway.data.name : '' }}
        </div>

        <div class="tw-text-[#9ba6b2] tw-text-xs">Адрес</div>
        <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">{{ element.order.data.object.data.address }}</div>

        <div class="tw-text-[#9ba6b2] tw-text-xs">Координаты</div>
        <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">{{ element.order.data.object.data.coordinates }}</div>

        <div class="tw-text-[#9ba6b2] tw-text-xs">Удаленность, км.</div>
        <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
          {{ element.order.data.object.data.distance_from_mkad }}
        </div>
      </div>
    </zem-collapse>

    <zem-collapse is-opened-default title="Отчет">
      <div class="tw-grid tw-grid-cols-3 tw-gap-x-1.5 tw-gap-y-2">
        <div class="tw-text-[#9ba6b2] tw-text-xs">Ответственный</div>
        <div class="tw-col-span-2">
          <zem-dropdown-list
            v-model="element.responsible_by"
            :loading="isLoadingResponsibleBy"
            :options="listUsers"
            class="tw-mt-0"
            search
            @on-search="getUsers('once', $event)"
          />
        </div>

        <div class="tw-text-[#9ba6b2] tw-text-xs">Отчет закрыт</div>
        <div class="tw-col-span-2 tw-leading-[0.6]">
          <zem-checkbox :value="element.report_closed_at !== null" disabled />
        </div>

        <div class="tw-text-[#9ba6b2] tw-text-xs">Дата и время закрытия отчета</div>
        <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">{{ element.report_closed_at }}</div>

        <template v-if="!isCreate">
          <div class="tw-text-[#9ba6b2] tw-text-xs">Отчет по мероприятию</div>
          <div class="tw-col-span-2 tw-leading-[0.6]">
            <zem-textarea v-model="element.report" class="tw-m-0" placeholder="Отчет" />
          </div>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Ссылка на отчет</div>
          <div class="tw-col-span-2 tw-flex tw-items-start">
            <zem-input v-model="element.report_url" class="mt-0" placeholder="Ссылка" />
          </div>
        </template>
      </div>
    </zem-collapse>

    <div v-show="isLoadingData" class="sidebar-right-preloader">
      <ZemPreloader :color="'dark'" :size="20" />
    </div>
  </div>
</template>

<script>
import ZemCard from '@/components/ui/ZemCard.vue'
import ZemCardTitle from '@/components/ui/ZemCardTitle.vue'
import ZemCollapse from '@/components/ui/ZemCollapse.vue'
import ZemInput from '@/components/ui/ZemInput.vue'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import {closeAcc, formattedDateTime} from '@/assets/scripts/scripts'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'
import EventsService from '@/services/events.service'
import ZemTextarea from '@/components/ui/ZemTextarea.vue'
import ZemCheckbox from '@/components/ui/Checkbox.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import ZemLink from '@/components/ui/ZemLink.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import ZemCounter from '@/components/ui/ZemCounter.vue'
import dayjs from 'dayjs'
import axios from 'axios'
import IEllipsis from '@/components/icons/iEllipsis.vue'
import ZemDropdownMenu from '@/components/ui/ZemDropdownMenu.vue'

export default {
  computed: {
    dayjs() {
      return dayjs
    },
  },
  components: {
    ZemDropdownMenu,
    IEllipsis,
    ZemLink,
    ZemButton,
    ZemCheckbox,
    ZemTextarea,
    ZemPreloader,
    ZemCardTitle,
    ZemCard,
    ZemCollapse,
    ZemInput,
    ZemCounter,
    ZemDropdownList,
    DatePicker,
  },
  data() {
    return {
      isCreate: true,
      isLoadingData: false,
      isLoadingOrders: false,
      isLoadingTypes: false,
      isLoadingStatuses: false,
      isLoadingPeoples: false,
      isLoadingResponsibleBy: false,
      isLoadingConfirmationStatuses: false,
      isLoadingPeculiarities: false,
      isOrderMenu: false,
      isConfirmed: false,
      element: {
        type_id: '',
        responsible_by: '',
        status_id: '',
        description: '',
        order_id: '',
        confirmation_status: '',
        report_closed_at: null,
        features: {
          data: [],
        },
      },
      orders: [],
      types: [],
      statuses: [],
      confirmationStatuses: [],
      listPeoples: [],
      listUsers: [],
      peoples: [
        {
          user: '',
          dateStart: null,
          duration: 0,
          dateEnd: null,
          isLoading: false,
          durationSuffix: '0 мин',
        },
      ],
      duration: 15,
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      peculiarities: [],
      peculiaritiesOptions: [],
    }
  },
  mounted() {
    const {id} = this.$route.params

    if (id) {
      this.getData(id)
    }

    EventsService.getPeculiarities().then(r => {
      this.peculiaritiesOptions = r.data.data
    })
  },
  methods: {
    closeAcc,
    formattedDateTime,
    updateTimes(type, index, e) {
      let {dateStart, duration, dateEnd, timeSize} = this.peoples[index]
      const durationMinutes =
        this.element.type_id && Object.hasOwn(this.element.type_id, 'timeSize')
          ? this.element.type_id.timeSize
          : timeSize
      if (type === 'start') {
        this.peoples[index].dateStart = e
        this.peoples[index].dateEnd = new Date(dayjs(e).add(duration, 'minutes').toISOString())
      } else if (type === 'duration' && dateStart) {
        if (e === '-') {
          if (duration > 0) {
            this.peoples[index].duration = duration - durationMinutes
          }
        } else {
          this.peoples[index].duration = duration + durationMinutes
        }
        this.peoples[index].dateEnd = new Date(
          dayjs(dateStart).add(this.peoples[index].duration, 'minutes').toISOString()
        )
      } else if (type === 'end') {
        this.peoples[index].duration = dayjs(dateEnd).diff(dateStart, 'minutes')
      }
    },
    closeRightSidebar() {
      this.$emit('close')
      this.$store.commit('sidebars/changeRightSidebar', false)
      const {view} = this.$route.query
      if (!view) this.$router.push({name: 'events'})
      else {
        this.$router.push({name: 'events', query: {view}})
      }
    },
    getTypes() {
      this.isLoadingTypes = true
      EventsService.getTypes()
        .then(res => {
          this.types = res.data.data.map(item => {
            return {value: item.id, title: item.name, timeSize: item.time_size}
          })
        })
        .finally(() => {
          this.isLoadingTypes = false
        })
    },
    getStatuses() {
      this.isLoadingStatuses = true
      EventsService.getStatuses()
        .then(res => {
          this.statuses = res.data.data
        })
        .finally(() => {
          this.isLoadingStatuses = false
        })
    },
    getOrders(search) {
      this.isLoadingOrders = true
      EventsService.getOrders(search)
        .then(res => {
          this.orders = res.map(item => ({value: item.id, title: item.title}))
        })
        .finally(() => {
          this.isLoadingOrders = false
        })
    },
    getPeoples(i, search) {
      this.peoples[i].isLoading = false
      EventsService.getPeoplesList(search).then(r => {
        this.listPeoples = r.map(item => {
          return {value: item.id, title: item.fio}
        })
        this.peoples[i].isLoading = false
      })
    },
    getUsers(i, search) {
      this.isLoadingResponsibleBy = true
      EventsService.getUsersList(search).then(r => {
        this.listUsers = r
        this.isLoadingResponsibleBy = false
      })
    },
    onCreateEvent(copy = false) {
      this.isLoadingData = true
      const {type_id, status_id, order_id, responsible_by, description, confirmed_at, features} = this.element
      return EventsService.createEvent({
        description,
        type_id: type_id ? type_id['value'] : null,
        status_id: status_id ? status_id['value'] : null,
        order_id: order_id ? order_id['value'] : null,
        responsible_by: responsible_by ? responsible_by['value'] : null,
        features: features.data.map(el => el.value),
        ...(this.peoples.length > 0
          ? {
              deadlines: this.peoples.map(people => {
                const {user, dateStart, dateEnd} = people
                return {
                  start_at: dayjs(dateStart).format('YYYY-MM-DD HH:mm:ss'),
                  finish_at: dayjs(dateEnd).format('YYYY-MM-DD HH:mm:ss'),
                  human_id: user['value'],
                }
              }),
            }
          : {}),
        ...(this.isConfirmed && !confirmed_at ? {confirm: 1} : {}),
        ...(!this.isConfirmed && confirmed_at ? {confirm: 0} : {}),
        ...(copy ? {confirmation_status: this.element.confirmation_status.name} : {}),
        ...(copy && this.element.report ? {report: this.element.report} : {}),
        ...(copy && this.element.report_url ? {report_url: this.element.report_url} : {}),
      })
        .then(r => {
          this.closeRightSidebar()
          EventsService.getEvents()
          this.$emit('on-change')
          this.$store.commit('events/itemsForDelete', [])
        })
        .finally(() => {
          this.isLoadingData = false
          this.confirm = false
        })
    },
    onChangeEvent() {
      this.isLoadingData = true
      const {
        type_id,
        status_id,
        confirmation_status,
        order_id,
        responsible_by,
        description,
        report,
        report_url,
        confirmed_at,
        features,
      } = this.element
      EventsService.updateEvent(this.$route.params.id, {
        description,
        ...(report ? {report: report} : {}),
        ...(report_url ? {report_url: report_url} : {}),
        type_id: type_id ? type_id['value'] : null,
        status_id: status_id ? status_id['value'] : null,
        confirmation_status: confirmation_status ? confirmation_status['value'] : null,
        order_id: order_id ? order_id['value'] : null,
        responsible_by: responsible_by ? responsible_by['value'] : null,
        features: features.data.map(el => el.value),
        ...(this.isConfirmed && !confirmed_at ? {confirm: 1} : {}),
        ...(!this.isConfirmed && confirmed_at ? {confirm: 0} : {}),
      })
        .then(() => {
          this.closeRightSidebar()
          EventsService.getEvents()
          this.$emit('on-change')
          this.$store.commit('events/itemsForDelete', [])
        })
        .finally(() => {
          this.isLoadingData = false
        })
      this.onChangeTimeSlots(this.$route.params.id)
    },
    onChangeTimeSlots(eventId, copy) {
      const requests = []
      this.peoples.forEach(people => {
        const {user, dateStart, duration, dateEnd} = people
        if (copy || (user && dateStart && duration && dateEnd && !Object.hasOwn(people, 'id'))) {
          return EventsService.createTimeSlot({
            event_id: eventId,
            human_id: user['value'],
            start_at: dayjs(dateStart).format('YYYY-MM-DD HH:mm:ss'),
            finish_at: dayjs(dateEnd).format('YYYY-MM-DD HH:mm:ss'),
          })
        } else if (user && dateStart && duration && dateEnd) {
          return EventsService.updateTimeSlot(people.id, {
            event_id: eventId,
            human_id: user['value'],
            start_at: dayjs(dateStart).format('YYYY-MM-DD HH:mm:ss'),
            finish_at: dayjs(dateEnd).format('YYYY-MM-DD HH:mm:ss'),
          })
        }
      })
      axios.all(requests).then(
        axios.spread((...responses) => {
          console.log(responses)
        })
      )
    },
    getData(id = null) {
      this.isCreate = false
      this.isLoadingData = true
      return EventsService.getEvent(id ? id : this.$route.params.id).then(r => {
        const {order, status, type, deadlines, responsibleBy, confirmed_at, features} = r.data.data
        this.isConfirmed = !!confirmed_at
        this.element = {
          ...r.data.data,
          type_id: type ? {value: type.data.id, title: type.data.name} : '',
          order_id: order ? {value: order.data.id, title: order.data.title} : '',
          status_id: status ? {value: status.data.id, title: status.data.name} : '',
          responsible_by: responsibleBy ? {value: responsibleBy.data.id, title: responsibleBy.data.name} : '',
          features: {
            data: features.data.map(el => ({value: el.id, title: el.name})),
          },
        }
        if (deadlines.data.length) {
          this.peoples = deadlines.data.map(item => {
            return {
              id: item.id,
              user: {value: item.human_id, title: item.human.data.fio},
              dateStart: new Date(item.start_at),
              duration: dayjs(item.finish_at).diff(item.start_at, 'minutes'),
              dateEnd: new Date(item.finish_at),
              isLoading: false,
              durationSuffix: this.handlerDuration(item),
              timeSize: type.data.time_size,
            }
          })
        } else {
          this.peoples = [
            {
              user: '',
              dateStart: null,
              duration: 0,
              dateEnd: null,
              isLoading: false,
              durationSuffix: '0 мин',
              timeSize: 15,
            },
          ]
        }
        this.isLoadingData = false
        this.$store.commit('events/itemsForDelete', [this.$route.params.id])
      })
    },
    handlerDuration(people) {
      const start = dayjs(people.start_at)
      const finish = dayjs(people.finish_at)
      const duration = finish.diff(start, 'minute')
      return this.formatTime(duration)
    },
    deletePeople(index) {
      EventsService.deleteTimeSlot(this.peoples[index].id).then(() => {
        this.peoples.splice(index, 1)
      })
    },
    formatTime(minutes) {
      const hours = Math.floor(minutes / 60)
      const remainingMinutes = minutes % 60
      let result = ''

      if (hours > 0) {
        result += `${hours}ч.`
      }
      if (remainingMinutes > 0) {
        if (result) {
          result += ' '
        }
        result += `${remainingMinutes}м.`
      }

      return result || '0м.'
    },
    resetForms(day = null) {
      this.element = {
        type_id: '',
        responsible_by: '',
        status_id: '',
        description: '',
        order_id: '',
        confirmation_status: '',
        report_closed_at: null,
        features: {
          data: [],
        },
      }
      this.isConfirmed = false
      this.peoples = [
        {
          user: day ? {title: day.name, value: day.id} : '',
          dateStart: day ? dayjs.unix(day.unix).toDate() : null,
          duration: 0,
          dateEnd: day ? dayjs.unix(day.unix).toDate() : null,
          isLoading: false,
          durationSuffix: '0m.',
          timeSize: 15,
        },
      ]
    },
  },
}
</script>

<style lang="scss">
.details-form__info-container {
  .zem-input {
    margin-top: 0;
  }
}

.sidebar-right-container {
  > .zem-card:first-child {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > div:last-child {
      display: flex;

      .block-id__delete {
        width: 17px;
        cursor: pointer;
      }
    }
  }

  > .sidebar-right-preloader {
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mx-input {
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  padding: 6px 6px 6px 8px;
  height: unset;
  border: 1px solid #f0f0f0;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.mx-input:hover,
.mx-input:focus {
  border-color: $color-accent;
}

.mx-btn:hover {
  border-color: $color-accent;
  color: $color-accent;
}

.mx-time-column .mx-time-item.active {
  color: $color-accent;
}

.mx-input::-webkit-input-placeholder {
  color: #9ba6b3;
}

.mx-input::-moz-placeholder {
  color: #9ba6b3;
}

.mx-icon-calendar {
  color: #9ba6b3;
  font-size: 13px;
}
</style>

<style lang="scss" scoped>
.sidebar-right-container {
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  position: relative;

  .block-id {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: $color-mine-shaft;

    &__edit {
      cursor: pointer;
    }

    &__label {
      width: 6px;
      height: 6px;
      background: #2eb67d;
      border-radius: 100px;
    }
  }
}

.colored {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: $color-accent;

  img {
    margin-right: 3px;
  }

  &--link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.details-form {
  &__title-block {
    font-weight: 400;
    font-size: 12px;
    color: $color-gull-gray;
    margin-right: 20px;
    flex: 1;
  }

  &__data-block {
    font-weight: 400;
    font-size: 12px;
    color: $color-mine-shaft;
    flex: 1;
  }

  &__info-container {
    display: flex;
    align-items: center;
    align-content: flex-start;
    text-align: left;

    .zem-dropdown {
      margin-top: 0;
    }

    .zem-textarea {
      margin-top: 0;
    }
  }

  &__btn-block {
    width: 140px;
  }
}

.zem-input-container {
  input {
    margin-top: 0;
  }
}

@media screen and (max-width: 1024px) {
  .details-form {
    width: 100%;
    margin: 0;
    padding: 90px 20px 20px 20px;
    display: flex;
    flex-direction: column;

    &__container {
      overflow-x: auto;
    }
  }
}
</style>
