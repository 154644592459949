<template>
  <div
    :class="{'is-focus': event.isFocus || $route.params.id === event.parent}"
    class="event tw-transition"
    @click="$emit('on-click', event)"
    @mouseleave="$emit('on-mouse-leave', event)"
    @mouseover="$emit('on-mouse-over', event)"
  >
    <span :class="colorStatus(event.slug)" class="event-status"></span>
    <span
      v-if="event['event_start_at'] === day.unix"
      :class="colorConfirmStatus(event.isConfirm)"
      class="event-left-status"
    ></span>
    <div class="event-body">
      <div class="event-type">
        <span class="tw-max-w-[75px] tw-truncate">{{ event.type }}</span>
        <span>{{ event.code }}</span>
      </div>
      <div class="event-time">
        <span>{{ event.time }}</span>
        <div class="tw-flex tw-items-center tw-gap-x-0.5">
          <i-box v-if="event.features.includes('nuzny-zakaznye-materialy')" class="tw-fill-[#3E3E3E] tw-size-3" />
          <i-rain v-if="event.features.includes('mozno-v-dozd')" class="tw-fill-[#0EC6C6] tw-size-3" />
          <i-sun v-if="event.features.includes('mozno-v-solnce')" class="tw-fill-[#ECD504] tw-size-3" />
          <i-cloud v-if="event.features.includes('mozno-v-tuci')" class="tw-fill-[#BDA9A9] tw-size-3" />
          <i-calendar v-if="event.features.includes('neizmennaya-data')" class="tw-fill-[#F35B5B] tw-size-3" />
          <i-calendar v-if="event.features.includes('mozno-ranse')" class="tw-fill-[#0DB2B2] tw-size-3" />
          <i-user-group v-if="event.countUsers > 1" class="tw-fill-[#3E3E3E] tw-size-3" />
        </div>
      </div>
      <div class="event-location">
        <span>{{ event.location }}</span>
        <span>{{ event.locationCode }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import IRain from '@/components/icons/iRain.vue'
import ISun from '@/components/icons/iSun.vue'
import IBox from '@/components/icons/iBox.vue'
import IUserGroup from '@/components/icons/iUserGroup.vue'
import ICloud from '@/components/icons/iCloud.vue'
import ICalendar from '@/components/icons/iCalendar.vue'

export default {
  name: 'EventsChartItem',
  components: {ICalendar, ICloud, IUserGroup, IBox, ISun, IRain},
  emits: ['on-click', 'on-mouse-leave', 'on-mouse-over'],
  props: {
    event: {
      type: Object,
      required: true,
    },
    day: {
      type: Object,
      required: true,
    },
  },
  methods: {
    colorStatus(status) {
      return status ? `status-${status}` : ''
    },
    colorConfirmStatus(isConfirm) {
      return isConfirm ? `is-confirmed` : 'is-not-confirmed'
    },
  },
}
</script>

<style lang="scss" scoped>
.event {
  min-width: 170px;
  border-radius: 4px;
  overflow: hidden;
  background-color: #f5f8fa;
  //max-width: 140px;
  cursor: pointer;
  position: relative;

  &.is-focus {
    background: #d5ebf4;
  }

  .event-status {
    display: block;
    height: 3px;
    width: 100%;
    background: #b6b6b6;

    &.status {
      &-process {
        background: #32c5ff;
      }

      &-closed_need_report {
        background: #ffc205;
      }

      &-closed_reported {
        background: #52c41a;
      }

      &-cancel {
        background: #ff0000;
      }
    }
  }

  .event-left-status {
    position: absolute;
    left: 0;
    top: 0;
    width: 3px;
    height: 100%;

    &.is-confirmed {
      background: #6dd23c;
    }

    &.is-not-confirmed {
      background: #fa541c;
    }
  }

  .event-body {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 2px 5px 2px 8px;
    font-size: 10px;
    color: #3e3e3e;

    .event-type {
      display: flex;
      justify-content: space-between;
      font-weight: 600;

      span:last-child {
        color: #9ba6b2;
      }
    }

    .event-time {
      display: flex;
      justify-content: space-between;

      span:last-child {
        color: #9ba6b2;
      }
    }

    .event-location {
      display: flex;
      justify-content: space-between;

      span:last-child {
        color: #9ba6b2;
      }
    }
  }
}

.events-is-collapse {
  .event-body {
    height: 20px;
    overflow: hidden;
  }
}
</style>