<template>
  <div>
    <SidebarLeft :class="{'sidebar-left-show': $store.state.sidebars.leftSidebar}">
      <FilterEventsForm v-show="$store.state.sidebars.leftSidebar" ref="filter" />
    </SidebarLeft>

    <TableContainer
      v-show="!isChart"
      ref="table-container"
      :columns="tableData"
      :counter-filter="$store.state.events.searchCount"
      :is-archive="$can('edit', 'event-read-archive')"
      :is-archiving="$can('edit', 'event-trash')"
      :is-loading-items="$store.state.other.isLoadingItems"
      :is-loading-new-item="$store.state.other.isLoadingNewItem"
      :is-remove-from-archive="$can('edit', 'event-delete')"
      :rows="$store.state.events.elements"
      :sorting-fields="sortingFields"
      name="events"
      title="Мероприятия"
      @update-data="getElements"
      @on-selected="selectItem"
      @delete-item="deleteItem"
      @archive-items="archiveItems"
      @return-from-archive="returnFromArchive"
      @on-change-archive="$router.push({name: 'events'})"
    >
      <template v-slot:head-buttons-after>
        <zem-link
          v-if="$store.state.events.selectedItemsForDelete.length === 1"
          class="tw-gap-x-1.5"
          @click="onCopyEvent"
        >
          <img :src="require('@/assets/icons/copy.svg')" alt="" width="16" />
          <span class="mobile-d-none">{{ $t('Copy') }}</span>
        </zem-link>
        <ZemButton
          v-if="$can('edit', 'event-create')"
          :disabled="isLoadingItem"
          :loading="isLoadingItem"
          icon
          @click="onCreateItem"
        >
          <img v-if="!isLoadingItem" :src="require('@/assets/icons/plus.svg')" alt="" class="tw-mb-[1px]" />
          <span class="mobile-d-none">Мероприятие</span>
        </ZemButton>
      </template>
    </TableContainer>

    <EventsChart
      ref="eventsChart"
      :class="!isChart ? 'tw-hidden' : 'tw-flex tw-flex-col'"
      @on-selected="selectItem"
      @on-create="onCreateItem"
    >
      <template v-slot:head-buttons>
        <ZemButton
          v-if="$can('edit', 'event-create')"
          :disabled="isLoadingItem"
          :loading="isLoadingItem"
          icon
          @click="onCreateItem"
        >
          <img v-if="!isLoadingItem" :src="require('@/assets/icons/plus.svg')" alt="" class="tw-mb-[1px]" />
          <span class="mobile-d-none">Мероприятие</span>
        </ZemButton>
      </template>
    </EventsChart>

    <SidebarRight
      :class="{
        'sidebar-right--show tw-w-[500px] tw-min-w-[500px] max-md:tw-w-full max-md:tw-min-w-full max-md:tw-h-[calc(100dvh-45px)] max-md:tw-overflow-auto':
          sidebars.rightSidebar,
      }"
    >
      <EventDetails v-show="!isAccessUpdate" ref="event-details" />
      <EventChange
        v-show="isAccessUpdate"
        ref="event-change"
        @close="isNew = false"
        @on-change="['chart', 'chart-orders'].includes($route.query.view) ? onActiveChart() : onActiveTable()"
      />
    </SidebarRight>
  </div>
</template>

<script>
import SidebarRight from '../components/SidebarRight'
import TableContainer from '@/components/TableContainer'
import SidebarLeft from '@/components/SidebarLeft.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import EventDetails from '@/components/Events/EventDetails.vue'
import FilterEventsForm from '@/components/FilterEventsForm.vue'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'
import ZemLink from '@/components/ui/ZemLink.vue'
import EventsChart from '@/components/Events/EventsChart.vue'
import IChart from '@/components/icons/iChart.vue'
import ITable from '@/components/icons/iTable.vue'
import EventsService from '@/services/events.service'
import EventChange from '@/components/Events/EventChange.vue'
import {errorHandler} from '@/assets/scripts/scripts'

export default {
  components: {
    EventChange,
    ITable,
    IChart,
    EventsChart,
    ZemLink,
    ZemPreloader,
    FilterEventsForm,
    EventDetails,
    ZemButton,
    SidebarLeft,
    SidebarRight,
    TableContainer,
  },

  data() {
    return {
      isNew: false,
      // isChart: false,
      isLoadingItem: false,
      sortingFields: ['order_id', 'created_at', 'code', 'exception'],
      tableData: [
        {
          label: 'Заказ',
          field: 'order.data.title',
          sortField: 'order_id',
          showInTable: true,
          type: 'link',
          url: 'orders-id',
          id: 'order_id',
          sort: 0,
        },
        {
          label: 'Ответственный',
          field: 'responsibleBy.data.name',
          sortField: 'responsibleBy',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Тип мероприятия',
          field: 'type.data.name',
          sortField: 'type',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Подтверждение',
          field: 'confirmation_status.title',
          sortField: 'confirmation_status',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Дата начала',
          field: 'start_at',
          sortField: '',
          showInTable: true,
          type: 'datetime',
          sort: 0,
        },
        {
          label: 'Дата окончания',
          field: 'finish_at',
          sortField: '',
          showInTable: false,
          type: 'datetime',
          sort: 0,
        },
        {
          label: 'Статус',
          field: 'status.data.name',
          sortField: 'status',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Описание',
          field: 'description',
          sortField: 'description',
          showInTable: true,
          sort: 0,
        },
      ],
    }
  },
  computed: {
    sidebars() {
      return this.$store.state.sidebars
    },
    isAccessUpdate() {
      return this.$can('edit', 'event-update')
    },
    isChart() {
      return ['chart', 'chart-orders'].includes(this.$route.query.view)
    },
  },
  async mounted() {
    const {id} = this.$route.params
    const {view, order_id} = this.$route.query
    if (['chart', 'chart-orders'].includes(view)) this.onActiveChart()
    else this.getElements()
    if (order_id) {
      this.onCreateItem()
    } else {
      this.$store.commit('sidebars/changeRightSidebar', id !== undefined)
    }
    this.$store.commit('sidebars/changeLeftSidebar', false)
  },
  watch: {
    isChart() {
      if (['chart', 'chart-orders'].includes(this.$route.query.view)) this.onActiveChart()
      else this.getElements()
    },
  },
  methods: {
    async onCopyEvent() {
      await this.$refs['event-change'].getData(this.$store.state.events.selectedItemsForDelete[0])
      await this.$refs['event-change'].onCreateEvent(true)
      await this.$toast.success('Мероприятие успешно скопировано')
    },
    onActiveChart() {
      this.$refs['eventsChart'].generateMembers()
      const {view} = this.$route.query
      if (!view) this.$router.push({path: this.$route.path, query: {view}})
    },
    onActiveTable() {
      const {view} = this.$route.query
      if (view) this.$router.push({path: this.$route.path})
      this.getElements()
    },
    redirectUrl() {
      const {view} = this.$route.query
      const name = this.$route.name
      if (['chart', 'chart-orders'].includes(view)) {
        if (name === 'events') this.$router.push({name: 'events', query: {view}})
        else this.$router.push({name: 'events-id', params: {id: this.$route.params.id}, query: {view}})
      } else {
        if (name === 'events') this.$router.push({name: 'events'})
        else this.$router.push({name: 'events-id', params: {id: this.$route.params.id}})
      }
    },
    onCreateItem(item) {
      this.isNew = true
      this.$store.commit('sidebars/changeRightSidebar', true)
      this.$store.commit('events/itemsForDelete', [])
      this.$refs['event-change'].resetForms(item)
      this.$refs['event-change'].$data.isCreate = true
      this.redirectUrl()
      const {order_id, order_title, view} = this.$route.query
      if (order_id || view === 'chart-orders') {
        if (view === 'chart') this.$refs['event-change'].$data.element.order_id = {value: order_id, title: order_title}
        else this.$refs['event-change'].$data.element.order_id = {value: item.id, title: item.title}
      }
    },
    getElements() {
      EventsService.getEvents()
    },
    async selectItem(data) {
      if (this.$store.state.events.isArchive) return
      this.isNew = false
      if (data.column.field !== 'checkbox') {
        this.$store.commit('sidebars/changeRightSidebar', true)
        if (this.$route.params.id !== data.row.id) {
          const {view} = this.$route.query
          if (!view) await this.$router.push({name: 'events-id', params: {id: data.row.id}})
          else await this.$router.push({name: 'events-id', params: {id: data.row.id}, query: {view}})
          if (this.isAccessUpdate) await this.$refs['event-change'].getData(data.row.id)
          else this.$refs['event-details'].getData(data.row.id)
        }
      }
    },
    async showRightSidebar() {
      this.$store.commit('events/itemsForDelete', [])
      this.$store.commit('sidebars/changeRightSidebar', true)
      const {view} = this.$route.query
      if (!view) await this.$router.push({name: 'events', params: {id: data.row.id}})
      else await this.$router.push({name: 'events', params: {id: data.row.id}, query: {view}})
    },
    async returnFromArchive() {
      await EventsService.archiveEvents()
      await this.getElements()
    },
    archiveItems() {
      this.$store.commit('sidebars/changeRightSidebar', false)
      this.$store.state.events.selectedItemsForDelete.forEach(async (item, index, arr) => {
        if (index + 1 !== arr.length) {
          await EventsService.deleteEvents()
        } else {
          await EventsService.deleteEvents().then(r => {
            this.$toast.success(r.data['message'])
            this.getElements()
          })
        }
      })
      this.$router.push({name: 'events'})
    },
    deleteItem() {
      this.$refs['table-container'].loadingDelete = true
      EventsService.deleteEvents(true)
        .then(r => {
          this.$toast.success(r.data.message)
          this.$refs['table-container'].loadingDelete = false
          this.$refs['table-container'].showDeleteModal = false
          this.$store.commit('sidebars/changeRightSidebar', false)
          this.$store.commit('events/itemsForDelete', [])
          this.getElements()
        })
        .catch(e => {
          errorHandler(e)
        })
    },
  },
}
</script>
