<template>
  <div class="filter-events">
    <div class="filter-events__btn-block">
      <div class="filter-events__btn-left">
        <div class="filter-events__btn-left__label">Фильтр</div>
        <div class="filter-events__btn-left__reset-btn" @click="resetFilter">Сбросить</div>
      </div>
      <div @click="closeLeftSidebar">
        <zem-icon class="cursor-pointer" name="close" size="18" />
      </div>
    </div>
    <div class="tw-font-normal tw-text-[10px] tw-text-[#9ba6b2] tw-mb-[3px] mt-3">Дата добавления</div>
    <div class="tw-mt-2 tw-space-y-2">
      <ZemCheckbox :value="filter.checkbox[0]" @input="inputCheckbox(0, $event)">Сегодня</ZemCheckbox>
      <ZemCheckbox :value="filter.checkbox[1]" @input="inputCheckbox(1, $event)">Последние 3 дня</ZemCheckbox>
      <ZemCheckbox :value="filter.checkbox[2]" @input="inputCheckbox(2, $event)">Последние 7 дней</ZemCheckbox>
      <ZemCheckbox :value="filter.checkbox[3]" @input="inputCheckbox(3, $event)">Последний месяц</ZemCheckbox>
      <div class="tw-flex tw-flex-col tw-mt-2">
        <label class="tw-font-normal tw-text-[10px] tw-text-[#9ba6b2] tw-mb-[3px]">Период начала</label>
        <date-picker
          v-model="date"
          format="DD.MM.YYYY"
          locale="ru"
          placeholder="Выбрать период"
          range
          value-type="format"
          @change="changeRangeDate('date', $event, 'start_at')"
        ></date-picker>
      </div>
      <div class="tw-flex tw-flex-col tw-mt-2">
        <label class="tw-font-normal tw-text-[10px] tw-text-[#9ba6b2] tw-mb-[3px]">Период завершения</label>
        <date-picker
          v-model="dateArchive"
          format="DD.MM.YYYY"
          locale="ru"
          placeholder="Выбрать период"
          range
          value-type="format"
          @change="changeRangeDate('dateArchive', $event, 'finish_at')"
        ></date-picker>
      </div>
    </div>
    <div class="filter-events__dropdown">
      <div class="zem-dropdown mb-0">
        <label class="tw-font-normal tw-text-[10px] tw-text-[#9ba6b2] tw-mb-[3px]">Тип мероприятия</label>
        <ZemDropdownList
          v-model="selectedType"
          :options="typesOptions"
          placeholder="Выбрать"
          class="my-0"
          @input="changeField($event, 'type_id')"
        ></ZemDropdownList>
      </div>
      <div class="zem-dropdown mb-0">
        <label class="tw-font-normal tw-text-[10px] tw-text-[#9ba6b2] tw-mb-[3px]">Статус мероприятия</label>
        <ZemDropdownList
          v-model="selectedStatus"
          :options="statusesOptions"
          placeholder="Выбрать"
          class="my-0"
          @input="changeField($event, 'status_id')"
        ></ZemDropdownList>
      </div>
      <div class="zem-dropdown mb-0">
        <label class="tw-font-normal tw-text-[10px] tw-text-[#9ba6b2] tw-mb-[3px]">Ответственный</label>
        <ZemDropdownList
          v-model="selectedResponsibleBy"
          :options="usersOptions"
          class="my-0"
          placeholder="Выбрать"
          search
          :loading="isLoadingResponsibleBy"
          @on-search="getUsers($event)"
          @input="changeField($event, 'responsible_by')"
        ></ZemDropdownList>
      </div>
      <div class="zem-dropdown mb-0">
        <label class="tw-font-normal tw-text-[10px] tw-text-[#9ba6b2] tw-mb-[3px]">Участники</label>
        <v-select
          v-model="selectedDeadlinesHumans"
          :options="peoplesOptions"
          :components="{OpenIndicator}"
          label="title"
          multiple
          class="my-0"
          placeholder="Выбрать"
          @search="getPeoples"
          @input="handlerSelected($event, 'selectedDeadlinesHumans', 'deadlines.human_id')"
        >
          <template #open-indicator="{attributes}">
            <img :src="require('@/assets/icons/dropdown-arrow.svg')" alt="" />
          </template>
          <template #no-options="{search, searching}">
            <template v-if="searching">
              Результаты не найдены для <em>{{ search }}</em>
            </template>
            <template v-else>Начните вводить текст для поиска</template>
          </template>
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>
import ZemCheckbox from './ui/Checkbox'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import moment from 'moment'
import ZemDropdownList from './ui/ZemDropdownList'
import 'vue-select/dist/vue-select.css'
import {closeAcc} from '@/assets/scripts/scripts'
import ZemIcon from '@/components/ui/ZemIcon'
import EventsService from '@/services/events.service'

export default {
  name: 'FilterEventsForm',

  components: {
    ZemCheckbox,
    DatePicker,
    ZemDropdownList,
    ZemIcon,
  },

  data() {
    return {
      selectedType: {
        value: '',
        title: '',
      },
      selectedStatus: {
        value: '',
        title: '',
      },
      selectedResponsibleBy: {
        value: '',
        title: '',
      },
      selectedDeadlinesHumans: [],
      typesOptions: [],
      statusesOptions: [],
      peoplesOptions: [],
      usersOptions: [],
      isLoadingResponsibleBy: false,
      isLoadingPeoples: false,
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      filter: {
        checkbox: [false, false, false, false],
      },
      date: 'Выбрать период',
      dateCreate: 'Выбрать период',
      dateArchive: 'Выбрать период',
      eventsStatus: {
        id: 'NxOpZowo9GmjKqdR',
        title: 'Активный',
      },
      partnersStatus: {
        title: 'Статус у партнера',
      },
      isDatePickerVisible: false,
      isDateCreatePickerVisible: false,
      isDateArchivePickerVisible: false,
      filtering: {},
      updateData: false,
    }
  },

  mounted() {
    if (this.$store.state.exchange.regions.length < 2) {
      Promise.all([
        EventsService.getTypes().then(r => {
          this.typesOptions = r.data.data
        }),
        EventsService.getStatuses().then(r => {
          this.statusesOptions = r.data.data
        }),
      ]).then(() => {
        this.updateData = true
      })
    } else {
      this.updateData = true
    }
  },

  watch: {
    selectedType() {
      if (this.updateData) this.getFilter()
    },
    selectedStatus() {
      if (this.updateData) this.getFilter()
    },
    selectedResponsibleBy() {
      if (this.updateData) this.getFilter()
    },
    selectedDeadlinesHumans() {
      if (this.updateData) this.getFilter()
    },
  },

  methods: {
    closeAcc,
    changeField(event, name) {
      if (event.value === null) {
        delete this.filtering[name]
      } else {
        this.filtering[name] = event.value
      }
    },
    handlerSelected(e, data, search) {
      if (e.length > 0) {
        if (e[e.length - 1].value === 0) {
          this[data] = [e[e.length - 1]]
        } else if (e[0].value === 0) {
          this[data] = [e.find(item => item.value !== 0)]
        } else {
          this[data] = e
        }
      }
      this.filteringEvents(this[data], search)
    },
    filteringEvents(array, field) {
      let newArr = array.map(item => item.value)
      if (newArr.includes(0) || array.length === 0) {
        delete this.filtering[field]
      } else {
        this.filtering[field] = newArr
      }
    },
    inputCheckbox(num, event) {
      let a = this.filter.checkbox[num]

      this.filter.checkbox = [false, false, false, false]

      if (event) {
        this.filter.checkbox[num] = true
      }
      this.date = 'Выбрать период'

      delete this.filtering['start_at']
      delete this.filtering['finish_at']
      delete this.filtering['status_active_at']
      delete this.filtering['partner_status_finish_at']
      if (!a) {
        if (num === 0) this.filtering['start_at'] = 'today'
        if (num === 1) this.filtering['start_at'] = 'last_three_days'
        if (num === 2) this.filtering['start_at'] = 'last_seven_days'
        if (num === 3) this.filtering['start_at'] = 'last_month'
      }

      this.getFilter()
    },
    changeRangeDate(date, event, name) {
      this.filter.checkbox = [false, false, false, false]
      if (event[0] === null && event[1] === null) {
        delete this.filtering[name]
      } else {
        this.filtering[name] =
          moment(this[date][0], 'DD.MM.YYYY').format('YYYY-MM-DD') +
          '|' +
          moment(this[date][1], 'DD.MM.YYYY').format('YYYY-MM-DD')
      }
      this.getFilter()
    },
    resetFilter() {
      this.$store.commit(`events/getSearch`, '')
      this.updateData = false
      this.filter.checkbox = [false, false, false, false]
      this.date = null
      this.dateCreate = null
      this.dateArchive = null
      delete this.filtering['type_id']
      delete this.filtering['start_at']
      delete this.filtering['finish_at']
      delete this.filtering['status_active_at']
      delete this.filtering['partner_status_finish_at']
      this.selectedType = {
        value: '',
        title: '',
      }
      this.selectedStatus = {
        value: '',
        title: '',
      }
      this.selectedResponsibleBy = {
        value: '',
        title: '',
      }
      this.selectedDeadlinesHumans = []
      this.filtering = {}
      this.getFilter()
      setTimeout(() => {
        this.updateData = true
      }, 10)
    },
    closeLeftSidebar() {
      this.$store.commit('sidebars/changeLeftSidebar', false)
    },
    async getFilter() {
      this.$store.commit('sidebars/changeRightSidebar', false)
      let data = ''
      let counter = 0
      let listCreatedName = ['today', 'last_three_days', 'last_seven_days', 'last_month']
      this.$store.commit('events/changeCurrentPage')

      for (let key in this.filtering) {
        data += key + ':' + this.filtering[key]
        if (!listCreatedName.includes(this.filtering[key])) {
          counter++
        }
        if (Object.keys(this.filtering).length !== counter) data += ';'
      }

      this.$store.commit('events/filterMain', data)
      this.$store.commit('events/getSearchCount', counter)

      await EventsService.getEvents()
    },
    getPeoples(search) {
      this.isLoadingPeoples = true
      if (search)
        EventsService.getPeoplesList(search).then(r => {
          this.peoplesOptions = r
          this.isLoadingPeoples = false
        })
      else {
        this.peoplesOptions = []
        this.isLoadingPeoples = false
      }
    },
    getUsers(search) {
      this.isLoadingResponsibleBy = true
      EventsService.getUsersList(search).then(r => {
        this.usersOptions = r
        this.isLoadingResponsibleBy = false
      })
    },
  },
}
</script>

<ul id="vs2__listbox" role="listbox" tabindex="-1" class="vs__dropdown-menu">
<li class="vs__no-options">
  Sorry, no matching options.
</li>
</ul>

<style lang="scss">
.vdpr-datepicker {
  position: relative;
  left: 35px;
  top: -200px;
  z-index: 9;
}

.vdpr-datepicker__calendar-dialog--inline {
  position: absolute;
}

.filter-events {
  .mx-datepicker {
    width: 100%;
  }

  .mx-input-wrapper {
    .mx-input {
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      padding: 6px 6px 6px 8px;
      height: unset;
      border: 1px solid #f0f0f0;
      -webkit-box-shadow: unset;
      box-shadow: unset;
    }

    .mx-input:hover,
    .mx-input:focus {
      border-color: $color-accent;
    }

    .mx-input::-webkit-input-placeholder {
      color: #9ba6b3;
    }

    .mx-input::-moz-placeholder {
      color: #9ba6b3;
    }

    .mx-icon-calendar {
      color: #9ba6b3;
      font-size: 13px;
    }
  }
}
</style>

<style lang="scss" scoped>
.filter-events {
  display: block;
  padding: 32px 24px;
  min-width: 192px;
  overflow-y: auto;
  height: 100%;

  &__btn-block {
    display: flex;
    justify-content: space-between;
  }

  &__btn-left {
    display: flex;
    align-items: center;

    &__label {
      font-weight: 700;
      font-size: 12px;
      line-height: 13px;
      color: $color-mine-shaft;
    }

    &__reset-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 13px;
      color: $color-accent;
      text-align: right;
      flex: 1;
      margin-left: 8px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__btn-period {
    width: 100%;
    padding: 5px 6px 5px 8px;
    border: 1px solid $color-catskill-grey;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    cursor: pointer;
    background-color: $color-white;
    color: $color-gull-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background: $color-iron;
      border: 1px solid $color-iron;
      //color: $color-oslo-gray;
      opacity: 0.8;
    }
  }

  &__icon-period {
    width: 16px;
    height: 16px;
    background-image: url('../assets/icons/calendar.svg');
  }
}

@media screen and (max-width: 1440px) {
  .filter-events {
    padding: 20px;
    min-width: 152px;
  }
}

@media screen and (max-width: 1024px) {
  .filter-events {
    &__dropdown,
    &__checkbox-block {
      width: 50%;
    }
  }
}

@media screen and (max-width: 450px) {
  .filter-events {
    &__dropdown,
    &__checkbox-block {
      width: 100%;
    }
  }
}
</style>
