<template>
  <textarea
    :disabled="disabled"
    :maxlength="maxLength"
    :placeholder="placeholder"
    :required="required"
    :value="value"
    class="zem-textarea"
    @blur="onBlurEvent"
    @click="onClickEvent"
    @focus="onFocusEvent"
    @input="onUpdateEvent($event.target.value)"
  />
</template>

<script>
export default {
  name: 'ZemTextarea',
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 9999,
    },
  },
  methods: {
    onClickEvent() {
      this.$emit('click')
    },
    onUpdateEvent(value) {
      this.$emit('input', value)
    },
    onFocusEvent() {
      this.$emit('focus')
    },
    onBlurEvent() {
      this.$emit('blur')
    },
  },
}
</script>

<style lang="scss" scoped>
.zem-textarea {
  padding: 5px 8px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  color: $color-mine-shaft;
  border: 1px solid $color-catskill-grey;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  resize: none;
  overflow: hidden;
  margin: 8px 0 0;

  &::placeholder {
    font-weight: 400;
    font-size: 12px;
    color: $color-gull-gray;
  }

  &:focus {
    outline: 0;
    border: 1px solid $color-accent;
  }
}
</style>
