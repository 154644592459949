<template>
  <div class="sidebar-right-container tw-w-full tw-items-center">
    <template v-if="currentItem">
      <zem-card>
        <div>
          <zem-card-title>Мероприятие: {{ currentItem.type ? currentItem.type.data.name : '' }}</zem-card-title>
          <div v-if="!closeAcc([3])" class="block-id">
            <span class="block-id__label second"></span>
            ID {{ currentItem['id'] }}
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-gap-x-3 cursor-pointer">
          <img :src="require('@/assets/icons/close.svg')" alt="" class="tw-w-5" @click="closeRightSidebar" />
        </div>
      </zem-card>

      <zem-collapse is-opened-default title="Общее">
        <div class="tw-grid tw-grid-cols-3 tw-gap-x-1.5 tw-gap-y-2">
          <div class="tw-text-[#9ba6b2] tw-text-xs">Заказ</div>
          <RouterLink
            :to="`/orders/${currentItem.order.data.id}`"
            class="tw-col-span-2 tw-text-xs tw-text-[#0DB2B2] hover:tw-underline tw-cursor-pointer"
            target="_blank"
          >
            {{ currentItem.order.data.title }}
          </RouterLink>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Тип</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
            {{ currentItem.type ? currentItem.type.data.name : '' }}
          </div>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Описание</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">{{ currentItem.description }}</div>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Статус</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
            {{ currentItem.status ? currentItem.status.data.name : '' }}
          </div>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Подтверждение</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
            {{ currentItem.confirmation_status ? currentItem.confirmation_status.title : '' }}
          </div>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Дата создания</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
            {{ currentItem.created_at ? dayjs.unix(currentItem.created_at).format('DD.MM.YYYY в HH:mm') : '' }}
          </div>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Кто создал</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
            {{ currentItem.created_by ? currentItem.createdBy.data.name : '' }}
          </div>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Дата изменения</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
            {{ currentItem.updated_at ? dayjs.unix(currentItem.updated_at).format('DD.MM.YYYY в HH:mm') : '' }}
          </div>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Кто изменил</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
            {{ currentItem.updated_by ? currentItem.updatedBy.data.name : '' }}
          </div>
        </div>
      </zem-collapse>

      <zem-collapse is-opened-default title="Участники и сроки">
        <p v-if="currentItem && !currentItem['deadlines'].data.length" class="tw-text-[#9ba6b2] tw-text-xs">
          Нет значений
        </p>
        <div v-else class="tw-divide-y tw-divide-[#F0F0F0] tw-flex tw-flex-col tw-gap-y-2.5">
          <div
            v-for="(people, index) in currentItem['deadlines'].data"
            :key="index"
            :class="{'tw-pt-2.5': index !== 0}"
          >
            <div class="tw-text-[#9ba6b2] tw-text-xs tw-flex tw-justify-between">
              <span>Участник</span>
              <zem-dropdown-menu>
                <template v-slot:options>
                  <RouterLink
                    target="_blank"
                    :to="`/counterparties/peoples/${people.human.data.id}`"
                    class="tw-block tw-text-nowrap tw-px-3 tw-py-1 hover:tw-bg-[#0DB2B2]/20 tw-text-[#3E3E3E] tw-transition tw-text-xs"
                  >
                    Перейти в карточку
                  </RouterLink>
                </template>
              </zem-dropdown-menu>
            </div>
            <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E] tw-mt-1">{{ people.human.data.fio }}</div>

            <div class="tw-grid tw-grid-cols-3 tw-gap-x-1.5 tw-gap-y-2 tw-mt-2">
              <div>
                <div class="tw-text-[#9ba6b2] tw-text-xs">Дата начала</div>
                <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E] tw-mt-1">
                  {{ handlerDateTime(people.start_at) }}
                </div>
              </div>

              <div>
                <div class="tw-text-[#9ba6b2] tw-text-xs">Длительность</div>
                <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E] tw-mt-1">{{ handlerDuration(people) }}</div>
              </div>

              <div>
                <div class="tw-text-[#9ba6b2] tw-text-xs">Дата окончания</div>
                <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E] tw-mt-1">
                  {{ handlerDateTime(people.finish_at) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </zem-collapse>

      <zem-collapse v-if="currentItem.order && currentItem.order.data.object" is-opened-default title="Объект">
        <div class="tw-grid tw-grid-cols-3 tw-gap-x-1.5 tw-gap-y-2">
          <div class="tw-text-[#9ba6b2] tw-text-xs">Артикул объекта</div>
          <RouterLink
            :to="`/objects/${currentItem.order.data.object.data.id}`"
            class="tw-col-span-2 tw-text-xs tw-text-[#0DB2B2] hover:tw-underline tw-cursor-pointer"
            target="_blank"
          >
            {{ currentItem.order.data.object.data.sku }}
          </RouterLink>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Регион</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
            {{ currentItem.order.data.object.data.region ? currentItem.order.data.object.data.region.data.name : '' }}
          </div>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Шоссе</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
            {{ currentItem.order.data.object.data.highway ? currentItem.order.data.object.data.highway.data.name : '' }}
          </div>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Адрес</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">{{ currentItem.order.data.object.data.address }}</div>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Координаты</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
            {{ currentItem.order.data.object.data.coordinates }}
          </div>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Удаленность, км.</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
            {{ currentItem.order.data.object.data.distance_from_mkad }}
          </div>
        </div>
      </zem-collapse>

      <zem-collapse is-opened-default title="Отчет">
        <div class="tw-grid tw-grid-cols-3 tw-gap-x-1.5 tw-gap-y-2">
          <div class="tw-text-[#9ba6b2] tw-text-xs">Ответственный</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
            {{ currentItem['responsible_by'] ? currentItem['responsibleBy'].data.name : '' }}
          </div>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Отчет закрыт</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
            <zem-checkbox v-model="currentItem['report_closed']" disabled />
          </div>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Дата и время закрытия отчета</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">{{ currentItem['report_closed_at'] }}</div>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Отчет по мероприятию</div>
          <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">{{ currentItem.report }}</div>

          <div class="tw-text-[#9ba6b2] tw-text-xs">Ссылка на отчет</div>
          <div class="tw-col-span-2 tw-flex tw-items-start">
            <a
              v-if="currentItem['report_url']"
              :href="currentItem['report_url']"
              class="tw-text-xs tw-text-[#0DB2B2] hover:tw-underline tw-cursor-pointer"
              target="_blank"
            >
              URL
            </a>
          </div>
        </div>
      </zem-collapse>
    </template>

    <div v-show="isLoadingData" class="sidebar-right-preloader">
      <ZemPreloader :color="'dark'" :size="20" />
    </div>
  </div>
</template>

<script>
import ZemCard from '@/components/ui/ZemCard.vue'
import ZemCardTitle from '@/components/ui/ZemCardTitle.vue'
import ZemCollapse from '@/components/ui/ZemCollapse.vue'
import ZemInput from '@/components/ui/ZemInput.vue'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import ZemSelect from '@/components/ui/Select.vue'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect.vue'
import ZemLink from '@/components/ui/ZemLink.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import ZemRadioButton from '@/components/ui/ZemRadioButton.vue'
import ZemCheckbox from '@/components/ui/Checkbox.vue'
import {closeAcc} from '@/assets/scripts/scripts'
import {MapIcon} from 'vue-feather-icons'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'
import JsonViewer from 'vue-json-viewer'
import EventsService from '@/services/events.service'
import ZemCounter from '@/components/ui/ZemCounter.vue'
import dayjs from 'dayjs'
import IEllipsis from '@/components/icons/iEllipsis.vue'
import ZemDropdownMenu from '@/components/ui/ZemDropdownMenu.vue'

export default {
  computed: {
    dayjs() {
      return dayjs
    },
  },
  components: {
    ZemDropdownMenu,
    IEllipsis,
    ZemCounter,
    ZemPreloader,
    ZemCheckbox,
    ZemRadioButton,
    ZemSelect,
    ZemCardTitle,
    ZemCard,
    ZemCollapse,
    ZemInput,
    ZemDropdownList,
    ZemMultiSelect,
    ZemLink,
    ZemButton,
    MapIcon,
    JsonViewer,
  },
  data() {
    return {
      isLoadingData: false,
      currentItem: null,
    }
  },
  mounted() {
    const {id} = this.$route.params

    if (id) {
      this.getData(id)
    }
  },
  methods: {
    closeAcc,
    handlerDateTime(date) {
      return dayjs(date).format('DD.MM.YYYY в HH:mm:ss')
    },
    closeRightSidebar() {
      this.$store.commit('sidebars/changeRightSidebar', false)
      this.$router.push({name: 'events'})
    },
    getData(id) {
      this.isLoadingData = true
      EventsService.getEvent(id)
        .then(r => {
          this.currentItem = r.data.data
          this.isLoadingData = false
          this.$store.commit('events/itemsForDelete', [id])
        })
        .catch(() => {
          this.$router.push({name: 'events'})
        })
    },
    handlerDuration(people) {
      const start = dayjs(people.start_at)
      const finish = dayjs(people.finish_at)
      const duration = finish.diff(start, 'minute')
      if (duration % 1440 === 0) return `${duration / 1440} д.`
      else if (duration % 60 === 0) return `${duration / 60} ч.`
      else return `${duration} мин.`
    },
  },
}
</script>

<style lang="scss">
.details-form__info-container {
  .zem-input {
    margin-top: 0;
  }
}

.sidebar-right-container {
  > .zem-card:first-child {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > div:last-child {
      display: flex;

      //img:not(:first-child) {
      //  margin-left: 8px;
      //}

      .block-id__delete {
        width: 17px;
        cursor: pointer;
      }
    }
  }

  > .sidebar-right-preloader {
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

<style lang="scss" scoped>
.sidebar-right-container {
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  position: relative;

  .block-id {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: $color-mine-shaft;

    &__edit {
      cursor: pointer;
    }

    &__label {
      width: 6px;
      height: 6px;
      background: #2eb67d;
      border-radius: 100px;
    }
  }
}

.colored {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: $color-accent;

  img {
    margin-right: 3px;
  }

  &--link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.details-form {
  &__title-block {
    font-weight: 400;
    font-size: 12px;
    color: $color-gull-gray;
    margin-right: 20px;
    flex: 1;
  }

  &__data-block {
    font-weight: 400;
    font-size: 12px;
    color: $color-mine-shaft;
    flex: 1;
  }

  &__info-container {
    display: flex;
    align-items: center;
    align-content: flex-start;
    text-align: left;

    .zem-dropdown {
      margin-top: 0;
    }

    .zem-textarea {
      margin-top: 0;
    }
  }

  &__btn-block {
    width: 140px;
  }
}

.zem-input-container {
  input {
    margin-top: 0;
  }
}

@media screen and (max-width: 1024px) {
  .details-form {
    width: 100%;
    margin: 0;
    padding: 90px 20px 20px 20px;
    display: flex;
    flex-direction: column;

    &__container {
      overflow-x: auto;
    }
  }
}
</style>
