<template>
  <section
    class="tw-w-full tw-px-5 tw-py-5 tw-h-dvh tw-overflow-hidden tw-shadow-[2px_0_8px_rgba(141,_141,_141,_0.12)] tw-z-[2] max-md:tw-h-[calc(100dvh-45px)]"
  >
    <div class="tw-flex tw-gap-x-4 tw-justify-between">
      <div>
        <div class="tw-flex tw-items-center tw-gap-x-11 max-md:tw-flex-col max-md:tw-items-start">
          <h1 class="tw-text-lg tw-font-semibold tw-leading-[18px] tw-text-[#3E3E3E]">
            График {{ $route.query.view === 'chart' ? 'занятости' : 'заказов' }}
          </h1>
          <div class="tw-flex tw-items-center tw-gap-x-4 max-md:tw-mt-2">
            <div class="tw-flex tw-items-center tw-justify-between tw-gap-x-4 tw-w-[135px]">
              <button class="tw-p-1.5 tw-rounded-full tw-bg-[#0DB2B2]" @click="dayOffset--">
                <i-chevron-left class="tw-w-3 tw-h-3 tw-text-white max-md:tw-rotate-90" stroke-width="3" />
              </button>
              <button
                :class="{'tw-text-[#0DB2B2]': dayOffset === 0}"
                class="tw-text-xs tw-font-semibold tw-transition hover:tw-text-[#0DB2B2]"
                @click="dayOffset = 0"
              >
                Сегодня
              </button>
              <button class="tw-p-1.5 tw-rounded-full tw-bg-[#0DB2B2]" @click="dayOffset++">
                <i-chevron-right class="tw-w-3 tw-h-3 tw-text-white max-md:tw-rotate-90" stroke-width="3" />
              </button>
            </div>
            <span class="tw-text-xs tw-font-semibold">{{
              months.length === 1
                ? `${firstLetterUppercase(months[0])} ${years[0]}`
                : years.length === 1
                ? `${firstLetterUppercase(months[0])} - ${firstLetterUppercase(months[1])} ${years[0]}`
                : `${firstLetterUppercase(months[0])} ${years[0]} - ${firstLetterUppercase(months[1])} ${years[1]}`
            }}</span>
          </div>
        </div>
      </div>
      <div class="tw-flex tw-items-center max-md:tw-items-start tw-gap-x-2 md:tw-gap-x-4">
        <slot name="head-buttons"></slot>
      </div>
    </div>
    <div class="tw-w-full tw-mt-5">
      <div class="tw-flex tw-items-center max-md:tw-flex-col max-md:tw-items-start max-md:tw-gap-y-4 tw-gap-2">
        <div class="tw-flex tw-items-center tw-gap-x-2">
          <div class="tw-relative tw-w-[220px]">
            <ZemInput
              v-model="searchQuery"
              class="m-0 tw-mt-0"
              placeholder="Поиск по ID, артикулу заказа"
              type="text"
            ></ZemInput>
            <img
              :src="require('@/assets/icons/search.svg')"
              alt=""
              class="tw-absolute tw-right-[9px] tw-top-2 tw-w-2.5"
            />
          </div>
          <p class="tw-text-[#9BA6B2] tw-text-[10px]">
            {{ $t('message.Found') }} 1 {{ $t('message.of') }}
            5
          </p>
        </div>
        <div class="tw-flex tw-items-center tw-gap-x-3 md:tw-ml-7 max-md:tw-overflow-auto max-md:tw-w-full">
          <div
            :class="{'tw-font-semibold tw-text-[#0DB2B2]': tab === 0}"
            class="tw-text-xs tw-py-0.5 tw-px-2 tw-rounded tw-bg-[#F5F5F5] tw-cursor-pointer tw-text-nowrap"
            @click="tab = 0"
          >
            День
          </div>
          <div
            :class="{'tw-font-semibold tw-text-[#0DB2B2]': tab === 1}"
            class="tw-text-xs tw-py-0.5 tw-px-2 tw-rounded tw-bg-[#F5F5F5] tw-cursor-pointer tw-text-nowrap"
            @click="tab = 1"
          >
            Неделя
          </div>
          <div
            :class="{'tw-font-semibold tw-text-[#0DB2B2]': tab === 2}"
            class="tw-text-xs tw-py-0.5 tw-px-2 tw-rounded tw-bg-[#F5F5F5] tw-cursor-pointer tw-text-nowrap"
            @click="tab = 2"
          >
            2 недели
          </div>
          <div
            :class="{'tw-font-semibold tw-text-[#0DB2B2]': tab === 3}"
            class="tw-text-xs tw-py-0.5 tw-px-2 tw-rounded tw-bg-[#F5F5F5] tw-cursor-pointer tw-text-nowrap"
            @click="tab = 3"
          >
            Месяц
          </div>
          <div
            :class="{'tw-font-semibold tw-text-[#0DB2B2]': tab === 4}"
            class="tw-text-xs tw-py-0.5 tw-px-2 tw-rounded tw-bg-[#F5F5F5] tw-cursor-pointer tw-text-nowrap"
            @click="tab = 4"
          >
            Квартал
          </div>
          <div
            :class="{'tw-font-semibold tw-text-[#0DB2B2]': tab === 5}"
            class="tw-text-xs tw-py-0.5 tw-px-2 tw-rounded tw-bg-[#F5F5F5] tw-cursor-pointer tw-text-nowrap"
            @click="tab = 5"
          >
            Год
          </div>
        </div>
      </div>
    </div>
    <div v-if="['chart', 'chart-orders'].includes($route.query.view)" class="tw-flex-grow tw-overflow-y-auto tw-mt-5">
      <!-- График заказов -->
      <table :class="{'max-md:tw-hidden': isMembersChart}" class="events-table">
        <thead class="tw-sticky tw-top-[-1px] tw-z-[2]">
          <tr class="tw-sticky tw-left-0 tw-z-[2]">
            <th
              class="tw-sticky tw-top-[-1px] tw-left-[1px] tw-outline tw-outline-neutral-100 tw-max-w-[300px] tw-bg-[#f8fafb] tw-text-[#9ba6b2] tw-py-0.5 tw-font-bold"
            >
              <div>
                <div :class="{'tw-text-[#0DB2B2] tw-font-semibold': isOpenAllItems}" @click="changeCollapseAllMembers">
                  <i-arrow-dropdown
                    :class="!isOpenAllItems ? 'tw-rotate-180' : 'tw-fill-[#0DB2B2]'"
                    class="tw-w-1.5"
                    fill="#9BA6B2"
                  />
                  {{ isMembersChart ? 'Участники' : 'Заказы' }}
                </div>
              </div>
            </th>
            <th
              v-for="(calendarDay, index) in calendarDays"
              :key="index"
              :class="[
                calendarDay.isCurrent && tab !== 0 ? 'tw-border-x-2 -tw-border-offset-4 tw-border-x-[#0DB2B2]' : '',
                ['сб', 'вс'].includes(calendarDay.weekday) ? 'tw-text-[#c24a4a]' : '',
              ]"
              class="tw-min-w-[100px] tw-text-[#9ba6b2] tw-py-0.5 tw-font-bold"
            >
              <div>
                {{ calendarDay.title }} <span>{{ calendarDay.weekday }}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in isMembersChart ? sortedMembers : sortedOrders"
            :key="index"
            :class="{'events-is-collapse': !item.isCollapse}"
          >
            <td
              class="tw-py-4 tw-p-3 tw-align-top tw-text-sm tw-sticky tw-left-[1px] tw-bg-white tw-outline tw-outline-1 tw-outline-[#f0f0f0] tw-z-[1]"
            >
              <div
                :class="{'tw-text-[#0DB2B2] tw-font-semibold': item.isCollapse}"
                class="tw-flex tw-items-center tw-gap-x-1 tw-cursor-pointer"
                @click="item.isCollapse = !item.isCollapse"
              >
                <i-arrow-dropdown
                  :class="!item.isCollapse ? '-tw-rotate-90' : 'tw-fill-[#0DB2B2]'"
                  class="tw-w-1.5 tw-min-w-1.5"
                  fill="#9BA6B2"
                />
                <span class="tw-whitespace-nowrap">{{ isMembersChart ? item.name : item.title }}</span>
              </div>
              <p v-if="isMembersChart" class="tw-text-[#9BA6B2] tw-pl-2.5 tw-text-xs">{{ item.position }}</p>
            </td>
            <td
              v-for="(day, indexDay) in item.days"
              :key="indexDay"
              :class="{'tw-border-x-2 -tw-border-offset-4 tw-border-x-[#0DB2B2]': day.isCurrent && tab !== 0}"
              class="tw-align-top tw-min-w-[250px]"
            >
              <div class="events-container">
                <template>
                  <EventsChartItem
                    v-for="(event, indexEvent) in events.filter(
                      el =>
                        el['start_at'] <= day.unix &&
                        el['finish_at'] >= day.unix &&
                        (isMembersChart ? el['human_id'] : el['order_id']) === item.id
                    )"
                    :key="indexEvent"
                    :day="day"
                    :event="event"
                    @on-click="onSelect(event)"
                    @on-mouse-leave="onUnFocusEvents"
                    @on-mouse-over="onFocusEvents(event)"
                  />
                </template>
                <div
                  class="event tw-transition tw-opacity-0 hover:tw-opacity-100 tw-bg-gray-50 tw-rounded tw-px-2 tw-py-2 tw-flex tw-justify-center tw-cursor-pointer"
                  @click="$emit('on-create', {...day, ...item})"
                >
                  <i-plus class="tw-w-2.5 tw-fill-[#9BA6B2]" />
                  <span class="mobile-d-none tw-ml-1.5 tw-text-[#9BA6B2] tw-text-xs">Мероприятие</span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- График занятости -->
      <table v-if="isMembersChart" class="events-table md:tw-hidden">
        <thead class="tw-sticky tw-top-[-1px] tw-z-[2]">
          <tr class="">
            <th class="tw-max-w-[300px] tw-sticky tw-left-0 tw-outline tw-outline-neutral-100 tw-bg-[#f8fafb] tw-z-[2]">
              <div>
                <div class="tw-text-[#0DB2B2] tw-font-semibold" @click="dayOffset = 0">Сегодня</div>
              </div>
            </th>
            <th
              v-for="(item, index) in isMembersChart ? sortedMembers : sortedOrders"
              :key="index"
              :class="{'events-is-collapse': !item.isCollapse}"
            >
              <div
                class="tw-pl-4 tw-py-3 tw-align-top tw-text-sm tw-w-[225px] tw-min-w-[225px] tw-sticky tw-bg-white tw-z-[1] tw-font-medium"
              >
                <div
                  :class="{'tw-text-[#0DB2B2] tw-font-semibold': item.isCollapse}"
                  class="tw-flex tw-items-center tw-gap-x-1 tw-cursor-pointer tw-flex-row"
                  @click="item.isCollapse = !item.isCollapse"
                >
                  <i-arrow-dropdown
                    :class="!item.isCollapse ? '-tw-rotate-90' : 'tw-fill-[#0DB2B2]'"
                    class="tw-w-1.5 tw-min-w-1.5"
                    fill="#9BA6B2"
                  />
                  <span class="tw-whitespace-nowrap">{{ item.name }}</span>
                </div>
                <p class="tw-text-[#9BA6B2] tw-pl-2.5 tw-text-xs">{{ item.position }}</p>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(calendarDay, index) in calendarDays" :key="index">
            <td
              :class="[
                calendarDay.isCurrent && tab !== 0 ? 'tw-border-y-2 -tw-border-offset-4 tw-border-y-[#0DB2B2]' : '',
                ['сб', 'вс'].includes(calendarDay.weekday) ? 'tw-text-[#c24a4a]' : '',
              ]"
              class="tw-min-w-[90px] tw-text-[#9ba6b2] tw-sticky tw-left-[1px] tw-bg-white tw-outline tw-outline-gray-100 tw-py-0.5 tw-font-bold tw-z-[3]"
            >
              <div class="tw-flex tw-flex-col tw-text-center">
                {{ calendarDay.title }} <span>{{ calendarDay.weekday }}</span>
              </div>
            </td>
            <td
              v-for="(member, index) in sortedMembers"
              :key="index"
              :class="[
                {'tw-border-y-2 -tw-border-offset-4 tw-border-y-[#0DB2B2]': calendarDay.isCurrent && tab !== 0},
                {'events-is-collapse': !member.isCollapse},
              ]"
              class="tw-align-top tw-min-w-[225px]"
            >
              <div class="events-container">
                <EventsChartItem
                  v-for="(event, indexEvent) in events.filter(
                    el =>
                      el['start_at'] <= calendarDay.unix &&
                      el['finish_at'] >= calendarDay.unix &&
                      el['human_id'] === member.id
                  )"
                  :key="indexEvent"
                  :day="calendarDay"
                  :event="event"
                  @on-click="onSelect(event)"
                  @on-mouse-leave="onUnFocusEvents"
                  @on-mouse-over="onFocusEvents(event)"
                />
                <div
                  class="event tw-transition tw-opacity-0 hover:tw-opacity-100 tw-bg-gray-50 tw-rounded tw-px-2 tw-py-2 tw-flex tw-justify-center tw-cursor-pointer"
                  @click="$emit('on-create', {...calendarDay, ...member})"
                >
                  <i-plus class="tw-w-2.5 tw-fill-[#9BA6B2]" />
                  <span class="mobile-d-none tw-ml-1.5 tw-text-[#9BA6B2] tw-text-xs">Мероприятие</span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import IChevronLeft from '@/components/icons/iChevronLeft.vue'
import IChevronRight from '@/components/icons/iChevronRight.vue'
import ZemInput from '@/components/ui/ZemInput.vue'
import IArrowDropdown from '@/components/icons/iArrowDropdown.vue'
import IRain from '@/components/icons/iRain.vue'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import {firstLetterUppercase} from '@/assets/scripts/scripts'
import EventsService from '@/services/events.service'
import axios from 'axios'
import PeopleAndCompaniesService from '@/services/peopleAndCompanies.service'
import IPlus from '@/components/icons/iPlus.vue'
import ISun from '@/components/icons/iSun.vue'
import ICloud from '@/components/icons/iCloud.vue'
import ICalendar from '@/components/icons/iCalendar.vue'
import IBox from '@/components/icons/iBox.vue'
import IUserGroup from '@/components/icons/iUserGroup.vue'
import EventsChartItem from '@/components/Events/EventsChartItem.vue'
import OrdersService from '@/services/orders.service'

export default {
  components: {
    EventsChartItem,
    IUserGroup,
    IBox,
    ICalendar,
    ICloud,
    ISun,
    IPlus,
    IRain,
    IArrowDropdown,
    ZemInput,
    IChevronRight,
    IChevronLeft,
  },
  data() {
    return {
      searchQuery: '',
      tab: 1,
      calendarDays: [],
      dayOffset: 0,
      currentDay: 'Сегодня',
      members: [],
      membersIds: [],
      months: [],
      years: [],
      events: [],
      orders: [],
      cancelTokenSource: null,
    }
  },
  watch: {
    async dayOffset() {
      await this.createdDays()
      const isNow = this.dayOffset === 0
      this.currentDay = isNow ? 'Сегодня' : dayjs().add(this.dayOffset, 'day').format('DD.MM')
      await this.generateEvents()
    },
    async tab() {
      await this.createdDays()
      const isNow = this.dayOffset === 0
      this.currentDay = isNow ? 'Сегодня' : dayjs().add(this.dayOffset, 'day').format('DD.MM')
      await this.generateEvents()
    },
    isMembersChart() {
      this.generateMembers()
    },
  },
  computed: {
    isOpenAllItems() {
      return this.isMembersChart
        ? this.members.every(member => member.isCollapse)
        : this.orders.every(order => order.isCollapse)
    },
    sortedMembers() {
      return this.members.slice().sort((a, b) => a.ordering - b.ordering)
    },
    sortedOrders() {
      return this.orders.slice().sort((a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1
        }
        return 0
      })
    },
    features() {
      return this.members.slice().sort((a, b) => a.ordering - b.ordering)
    },
    isMembersChart() {
      return this.$route.query.view === 'chart'
    },
  },
  async mounted() {
    dayjs.locale('ru')
    await this.createdDays()
    // await this.generateMembers()
  },
  methods: {
    dayjs,
    firstLetterUppercase,
    changeCollapseAllMembers() {
      if (this.isOpenAllItems) {
        this.members.forEach(member => {
          member.isCollapse = false
        })
        this.orders.forEach(order => {
          order.isCollapse = false
        })
      } else {
        this.members.forEach(member => {
          member.isCollapse = true
        })
        this.orders.forEach(order => {
          order.isCollapse = true
        })
      }
    },
    createdDays() {
      this.calendarDays = []
      this.months = []
      this.years = []
      if (this.tab === 0) {
        for (let i = 0; i < 1; i++) {
          const date = dayjs().add(i + this.dayOffset, 'day')
          this.addDay(date)
        }
      } else if (this.tab === 1) {
        for (let i = 0; i < 7; i++) {
          const date = dayjs()
            .subtract(2, 'day')
            .add(i + this.dayOffset, 'day')
          this.addDay(date)
        }
      } else if (this.tab === 2) {
        for (let i = 0; i < 14; i++) {
          const date = dayjs()
            .subtract(2, 'day')
            .add(i + this.dayOffset, 'day')
          this.addDay(date)
        }
      }
    },
    addDay(date) {
      this.calendarDays.push({
        title: date.format('DD.MM'),
        date: date.format('DD.MM.YYYY'),
        unix: date.hour(0).minute(0).second(0).unix(),
        weekday: date.format('dd'),
        isCurrent: dayjs().format('DD.MM.YYYY') === date.format('DD.MM.YYYY'),
      })
      if (!this.months.includes(date.format('MMMM'))) {
        this.months.push(date.format('MMMM'))
      }
      if (!this.years.includes(date.format('YYYY'))) {
        this.years.push(date.format('YYYY'))
      }
    },
    generateMembers(type = 0) {
      if (this.isMembersChart) {
        PeopleAndCompaniesService.getPeoplesNoLimit().then(r => {
          this.members = r.map(user => {
            return {
              id: user.id,
              user_id: user.user_id,
              name: user.fio,
              ordering: user.ordering || 0,
              position: user.event_position.data.title || 'Должность не указана',
              isCollapse: false,
              days: this.generateDays(),
            }
          })
          this.generateEvents(type)
        })
      } else {
        OrdersService.getOrderForEvents().then(r => {
          this.orders = r.data.data.map(order => {
            return {
              id: order.id,
              title: order.title,
              isCollapse: false,
              days: this.generateDays(),
            }
          })
          this.generateEvents(type)
        })
      }
    },
    generateEvents(type) {
      if (this.isMembersChart) {
        this.members.forEach(member => {
          member.days = this.generateDays()
        })
      } else {
        this.orders.forEach(order => {
          order.days = this.generateDays()
        })
      }

      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel('Request canceled by the user.')
      }

      this.cancelTokenSource = axios.CancelToken.source()
      let startDate = dayjs.unix(this.calendarDays[0].unix).format('YYYY-MM-DD')
      const endDate = dayjs.unix(this.calendarDays[this.calendarDays.length - 1].unix).format('YYYY-MM-DD')

      let search = `start_at:${startDate}|${endDate};finish_at:${startDate}|${endDate}`

      if (type === 1) search = `start_at:${endDate}|${endDate};finish_at:${endDate}|${endDate}`

      EventsService.getFilteringEvents(search, this.cancelTokenSource.token).then(r => {
        this.events = []

        const events = r.filter(el => el['deadlines'].data.length > 0)
        events.forEach(ev => {
          const ids = ev['deadlines'].data.map(item => item.human_id)
          const uniqueIds = new Set(ids)
          ev['deadlines'].data.forEach(deadline => {
            this.events.push({
              isFocus: false,
              parent: ev.id,
              status: ev.status ? ev.status.data.name : 'Статус не указан',
              slug: ev.status ? ev.status.data.slug : null,
              countUsers: uniqueIds.size,
              isConfirm: !!ev.confirmed_at,
              type: ev.type ? ev.type.data.name : 'Тип не указан',
              features: ev.features.data.map(el => el.slug),
              code: !this.isMembersChart
                ? deadline.human
                  ? deadline.human.data.fio
                  : 'Исполнитель не указан'
                : ev.order
                ? ev.order.data.title
                : 'Заказ не указан',
              time: this.handlerTime(ev),
              location:
                ev.order && ev.order.data.object ? ev.order.data.object.data.highway.data.name : 'Локация не указана',
              locationCode: ev.order && ev.order.data.object ? ev.order.data.object.data.distance_from_mkad : '',
              event_start_at: ev['start_at'],
              start_at: dayjs(deadline['start_at']).startOf('day').unix(),
              finish_at: dayjs(deadline['finish_at']).startOf('day').unix(),
              human_id: deadline['human_id'],
              order_id: ev['order_id'],
            })
          })
        })
      })
    },
    handlerTime(ev) {
      const startTime = dayjs.unix(ev['start_at']).format('HH:mm')
      const finishTime = dayjs.unix(ev['finish_at']).format('HH:mm')

      const start = dayjs(ev.start_at)
      const finish = dayjs(ev.finish_at)
      const duration = finish.diff(start, 'minute')
      if (startTime === finishTime && duration > 0) {
        return `Весь день${startTime === '00:00' ? '' : ' с ' + startTime}`
      }
      return `${dayjs.unix(ev['start_at']).format('HH:mm')}-${dayjs.unix(ev['finish_at']).format('HH:mm')}`
    },
    generateDays() {
      let days = []
      for (const i in this.calendarDays) {
        const unixDate = this.calendarDays[i].unix
        days.push({
          date: this.calendarDays[i].title,
          isCurrent: this.calendarDays[i].isCurrent,
          unix: unixDate,
        })
      }
      return days
    },
    onFocusEvents(event) {
      this.events.forEach(ev => {
        if (ev.parent === event.parent) {
          ev.isFocus = true
        }
      })
    },
    onUnFocusEvents() {
      this.events.forEach(ev => {
        ev.isFocus = false
      })
    },
    async onSelect(event) {
      this.$emit('on-selected', {
        row: {
          id: event.parent,
        },
        column: {
          field: '',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.events-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  border: 1px solid #f0f0f0;

  thead {
    background-color: #f8fafb;

    tr {
      td:first-child {
        border: 0;
      }

      td:nth-child(2) {
        border-left: 0;
      }

      th:last-child {
        border: 1px solid #f0f0f0;
      }
    }
  }

  tbody {
    tr {
      td:first-child {
        border: 0;
      }

      td:nth-child(2) {
        border-left: 0;
      }

      td:last-child {
        border: 1px solid #f0f0f0;
      }
    }
  }

  .events-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  td,
  th {
    border: 1px solid transparent;
  }

  tr:not(:first-child):not(:last-child) td,
  tr:not(:first-child):not(:last-child) th,
  tr td:not(:first-child):not(:last-child),
  tr th:not(:first-child):not(:last-child),
  tr:first-child td:not(:first-child):not(:last-child),
  tr:first-child th:not(:first-child):not(:last-child),
  tr:last-child td:not(:first-child):not(:last-child),
  tr:last-child th:not(:first-child):not(:last-child) {
    border-color: #f0f0f0;
  }

  th {
    &:first-child {
      div {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;

        div {
          height: 100%;
          display: flex;
          align-items: center;
          gap: 4px;
          cursor: pointer;
        }
      }
    }

    &:not(:first-child) div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  th,
  td {
    &:not(:first-child) {
      //width: 144px;
    }
  }

  td {
    color: #3e3e3e;
    padding: 2px;
    //border: 1px solid #f0f0f0;
  }
}
</style>
